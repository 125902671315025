import { SOCKET_BASE_URL } from "@/api/config"
import { UserDto } from "@/types/User"
import { useEffect, useRef, useState } from "react"
import { useRouteLoaderData } from "react-router-dom"
import PlayerImage from "../../../../../assets/player.png"
import { useToast } from "@/shadcd/components/ui/use-toast"

const TOASTER_DURATION = 1000; // ms

interface Player {
  username: string
  pos: {x: number, y: number} // 0-10
}

interface GameState {
  [id: string]: Player
}

export default function HappoMotelCanvas() {
  // Game settings
  const SCREEN_WIDTH = 500
  const SCREEN_HEIGHT = 500
  const PLAYER_SIZE = 50
  
  const currentUser = useRouteLoaderData("protected") as UserDto
  const [gameState, setGameState] = useState<GameState>({})
  const [socket, setSocket] = useState<WebSocket | null>(null); 
  const canSend = useRef(true);
  const {toast} = useToast();

  window.addEventListener("keyup", (e) => {
    if (canSend.current) {
      if (e.key === "a") {
        sendMove("left")
      }
      if (e.key === "d") {
        sendMove("right")
      }
      if (e.key === "w") {
        sendMove("up")
      }
      if (e.key === "s") {
        sendMove("down")
      }
    }
  })

  // Player image
  const image = new Image();
  image.src = PlayerImage
  
  useEffect(() => {
    const wbUrl = `${SOCKET_BASE_URL}/happomotel/${currentUser.username}`;
    const ws = new WebSocket(wbUrl)
    setSocket(ws);
  }, [currentUser.username]);

  // Listen socket events
   useEffect(() => {
    if (socket) {
      socket.onopen = () => {
        console.log("WebSocket connected...")
      };

      // Handle messages from socket server
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data) as GameState
        setGameState(data)
      };

      socket.onclose = () => {
        console.log('WebSocket disconnected...');
      };
      
      socket.onerror = () => {
        console.log('WebSocket error...');
        toast({
          title: "Offline",
          variant: "destructive",
          duration: TOASTER_DURATION
        })
      };

      return () => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.close();
        }
      };
    }
  }, [socket])

  const sendMove = (dir: string) => {
    if (socket) {
      var data = {
        type: "MOVE",
        dir: dir
        
      }
      socket.send(JSON.stringify(data));
      canSend.current = false
      setTimeout(() => canSend.current = true, 10);
    }
  };

  useEffect(() => {
      // TODO: This cant be best solution for this kinda job.
      //       React now re-renders canvas in every message anyone does.
      //       Is there ligher way to re-render content of canvas??
      const gameGanvas = document.querySelector("#game") as HTMLCanvasElement;
      const ctx = gameGanvas.getContext("2d") as CanvasRenderingContext2D;  
      
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, SCREEN_WIDTH, SCREEN_HEIGHT);
      
      for (const [name, player] of Object.entries(gameState)) {
        
        // Draw player
        // TODO: This should only happend if player is inside the canvas.
        //       This could improve performance since players who are outside
        //       will not be rendered. 
        ctx.drawImage(image, player.pos.x * PLAYER_SIZE, player.pos.y * PLAYER_SIZE, PLAYER_SIZE, PLAYER_SIZE)
        ctx.stroke();
        
        // Draw player name 
        ctx.font = "12px Arial"; 
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        ctx.fillStyle = "white";
        const textX = player.pos.x * PLAYER_SIZE + PLAYER_SIZE / 2; // Center text above the block
        const textY = player.pos.y * PLAYER_SIZE - 2; // Slightly above the block
        ctx.fillText(name, textX, textY);
      }
  }, [gameState])

  return (
    <div className={`relative w-[${SCREEN_WIDTH}px] h-[${SCREEN_HEIGHT}px] border border-main-blue`}>
        <canvas width={SCREEN_WIDTH} height={SCREEN_HEIGHT} id="game"></canvas>
    </div>
  )
}