import { useToast } from "@/shadcd/components/ui/use-toast";
import { ApiResponse } from "@/types/ApiResponse";
import { useEffect } from "react";
import { useActionData, useNavigate } from "react-router-dom";

const TOASTER_DURATION = 1000; // ms

// TODO: This should be renamed since it actually
// handles only toaster.

/**
 * Get action data as ApiResponse and
 * to matching toaster.  
 * @param success Show toasters for success responses
 * @param error Show toasters for error responses
 * @returns Actual data from response as T
 */
export default function useApiAction<T>(success: boolean = true, error: boolean = true): T | undefined {
  const navigate = useNavigate();
  const a = useActionData() as ApiResponse<T>;
  const {toast} = useToast();

  useEffect(() => {
    if (a?.status === 200 && success) {
      toast({
        title: a?.message,
        duration: TOASTER_DURATION
      })
    } else if (a?.status === 500 && error) {
      toast({
        title: a?.message,
        variant: "destructive",
        duration: TOASTER_DURATION
      })
    }

    // Check redirects
    if (a?.redirect) {
      if (a.redirect === "prev") {
        navigate(-1);
      } else {
        navigate(a.redirect)
      }
    }
  }, [a])
  return a?.data || undefined;
}