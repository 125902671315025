import { Outlet } from "react-router-dom";
import LeftNav from "../../left-nav/LeftNav";
import Chatbot from "@/components/other/ChatBot";

export default function ProtectedView() {
  return (
    <div>
      <TopHeader />
      <Outlet />
      <Chatbot />
    </div>
  );
}

const TopHeader = () => {
  return (
    <div className="w-screen fixed flex p-4">
        <LeftNav />
    </div>
  )
}